<template>
    <div class="imgPreview">
        <img :src="path(propsData.image)" alt="" />
        <div class="info">
          <p class="title">{{ propsData.title }}</p>
          <p class="desc" v-html="propsData.description"></p>
          <router-link  :to="propsData.button_link" class="btn">{{ propsData.button_title }}</router-link>
        </div>
      </div>
</template>

<script>
    export default {
        props: {
            propsData: {
                type: [Array, Object],
            },
        },
    }
</script>

<style lang="sass" scoped>
.imgPreview
    position: relative
    margin-top: 45px
    .info
        max-width: 520px
        position: absolute
        bottom: 0px
        right: 0px
        text-align: left
        background-color: $c-gray
        padding: 35px
        @media (max-width: 576px)
            position: static
        .title
            font-weight: 500
            font-size: 25px
            line-height: 140%
            color: #FFFFFF
            margin-top: 0px
        .desc
            font-weight: 300
            font-size: 17px
            line-height: 150%
            color: $t-gray
</style>
